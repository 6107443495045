<template>
    <div>
        loading..
    </div>
</template>

<script>
// import axios from 'axios'

export default {
    methods: {
        // getDataFromLocalStorage() {
        //     // localStorage.setItem('', {})
        //     this.token = localStorage.getItem('token')
        //     this.courseId = localStorage.getItem('courseId')
        //     this.liveClassId = localStorage.getItem('liveClassId')

        //     if ( ! ( this.token && this.courseId && this.liveClassId ) )
        //         this.$router.push('/')
        // },
        // removeOurDataFromLocalStorage() {
        //     localStorage.removeItem('token')
        //     localStorage.removeItem('courseId')
        //     localStorage.removeItem('liveClassId')
        // },
        leaveRequest() {
            // http requestte başka bir sorun olabileceği gerekçesiyle sorgudan önce event fırlatmak
            window.top.postMessage('meetingEnded', '*')

            window.top.postMessage('meetingEndedRequest', '*')

            // axios({
            //     method: 'patch',
            //     url: `https://api.malikampus.com/api/v2/courses/${this.courseId}/live-classes/${this.liveClassId}`,
            //     data: {
            //         is_live: false
            //     },
            //     headers: {
            //         Authorization: `Token ${this.token}`
            //     }
            // })
            // .then( () => {
            // })
            // .catch( error => {
            //     console.error(error)
            // })
        },
        sendHomePage() {
            this.$router.push('/')
        }
    },
    mounted() {
        // this.getDataFromLocalStorage()
        // this.removeOurDataFromLocalStorage()
        this.leaveRequest()
        this.sendHomePage()
    }
}
</script>

<style>
#zmmtg-root {
    display: none;
}
</style>